const MapperFunction = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[4rem] box-border max-w-full text-center text-[1.125rem] text-secondary font-body mq750:pb-[2.625rem] mq750:box-border">
      <div className="w-[62rem] flex flex-col items-start justify-start gap-[4rem] max-w-full mq750:gap-[1rem] mq1050:gap-[2rem]">
        <div className="self-stretch flex flex-row items-start justify-center py-[0rem] px-[1.25rem]">
          <div className="flex flex-col items-end justify-start gap-[0.5rem]">
            <div className="flex flex-row items-start justify-end py-[0rem] pr-[1.875rem] pl-[2rem]">
              <b className="relative tracking-[0.16em] uppercase">
                Always Caring
              </b>
            </div>
            <h1 className="m-0 relative text-[2rem] font-normal font-display-2 text-primary mq450:text-[1.188rem] mq1050:text-[1.625rem] mq450:mr-12 mq1050:mr-6">
              Our Specialties
            </h1>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start text-[1rem] text-black">
          <div className="self-stretch flex flex-row flex-wrap items-start justify-center [row-gap:20px]">
            <div className="flex-1 flex flex-col items-start justify-start min-w-[11.625rem] max-w-[15.5rem]">
              <div className="self-stretch flex flex-col items-center justify-start pt-[3.062rem] px-[1.25rem] pb-[3.125rem] gap-[0.5rem] z-[1] border-[0.2px] border-solid border-gray-300">
                <div className="flex flex-row items-start justify-start py-[0rem] pr-[1rem] pl-[0.875rem]">
                  <img
                    className="h-[2.75rem] w-[3.125rem] relative"
                    alt=""
                    src="/vector-41.svg"
                  />
                </div>
                <div className="w-[4.938rem] relative inline-block min-w-[4.938rem]">
                  Neurology
                </div>
                <div className="w-[15.5rem] h-[10.75rem] relative box-border hidden border-[0.2px] border-solid border-gray-300" />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start pt-[3.062rem] px-[4rem] pb-[3.125rem] gap-[0.5rem] z-[2] border-[0.2px] border-solid border-gray-300">
                <div className="w-[15.5rem] h-[10.75rem] relative box-border hidden border-[0.2px] border-solid border-gray-300" />
                <div className="flex flex-row items-start justify-start py-[0rem] px-[2.187rem]">
                  <img
                    className="h-[2.75rem] w-[3.125rem] relative z-[3]"
                    alt=""
                    src="/vector-41.svg"
                  />
                </div>
                <div className="self-stretch relative inline-block min-w-[7.5rem] z-[3]">
                  Ophthalmology
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-start min-w-[11.625rem] max-w-[15.5rem] text-lightsteelblue-100">
              <div className="self-stretch rounded-8xs bg-primary flex flex-col items-center justify-start pt-[3.125rem] px-[1.25rem] pb-[3.187rem] gap-[0.5rem]">
                <div className="w-[15.5rem] h-[10.75rem] relative rounded-8xs bg-primaryx hidden" />
                <img
                  className="w-[3.125rem] h-[2.75rem] relative z-[1]"
                  alt=""
                  src="/vector-61.svg"
                />
                <div className="flex flex-row items-start justify-start py-[0rem] px-[0.062rem]">
                  <div className="relative inline-block min-w-[3rem] z-[1]">
                    Bones
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start pt-[3.062rem] px-[4.125rem] pb-[3.125rem] gap-[0.5rem] z-[4] text-black border-[0.2px] border-solid border-gray-300">
                <div className="w-[15.5rem] h-[10.75rem] relative box-border hidden border-[0.2px] border-solid border-gray-300" />
                <div className="flex flex-row items-start justify-start py-[0rem] px-[2.062rem]">
                  <img
                    className="h-[2.75rem] w-[3.125rem] relative z-[5]"
                    alt=""
                    src="/vector-41.svg"
                  />
                </div>
                <div className="relative inline-block min-w-[7.25rem] z-[5]">
                  Cardiovascular
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-start min-w-[11.625rem] max-w-[15.5rem]">
              <div className="self-stretch flex flex-col items-center justify-start pt-[3.062rem] px-[1.25rem] pb-[3.125rem] gap-[0.5rem] z-[5] border-[0.2px] border-solid border-gray-300">
                <div className="w-[15.5rem] h-[10.75rem] relative box-border hidden border-[0.2px] border-solid border-gray-300" />
                <div className="flex flex-row items-start justify-start py-[0rem] pr-[0.812rem] pl-[0.687rem]">
                  <img
                    className="h-[2.75rem] w-[3.125rem] relative z-[6]"
                    alt=""
                    src="/vector-41.svg"
                  />
                </div>
                <div className="w-[4.563rem] relative inline-block min-w-[4.563rem] z-[6]">
                  Oncology
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start pt-[3.062rem] px-[4.562rem] pb-[3.125rem] gap-[0.5rem] z-[6] border-[0.2px] border-solid border-gray-300">
                <div className="w-[15.5rem] h-[10.75rem] relative box-border hidden border-[0.2px] border-solid border-gray-300" />
                <div className="flex flex-row items-start justify-start py-[0rem] px-[1.625rem]">
                  <img
                    className="h-[2.75rem] w-[3.125rem] relative z-[7]"
                    alt=""
                    src="/vector-41.svg"
                  />
                </div>
                <div className="self-stretch relative inline-block min-w-[6.375rem] z-[7]">
                  Pulmonology
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-start min-w-[11.625rem] max-w-[15.5rem]">
              <div className="self-stretch flex flex-col items-start justify-start pt-[3.062rem] px-[2.75rem] pb-[3.125rem] gap-[0.5rem] z-[7] border-[0.2px] border-solid border-gray-300">
                <div className="w-[15.5rem] h-[10.75rem] relative box-border hidden border-[0.2px] border-solid border-gray-300" />
                <div className="flex flex-row items-start justify-start py-[0rem] pr-[3.5rem] pl-[3.375rem]">
                  <img
                    className="h-[2.75rem] w-[3.125rem] relative z-[8]"
                    alt=""
                    src="/vector-41.svg"
                  />
                </div>
                <div className="self-stretch relative z-[8]">
                  Otorhinolaryngology
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start pt-[3.062rem] px-[4rem] pb-[3.125rem] gap-[0.5rem] z-[8] border-[0.2px] border-solid border-gray-300">
                <div className="w-[15.5rem] h-[10.75rem] relative box-border hidden border-[0.2px] border-solid border-gray-300" />
                <div className="flex flex-row items-start justify-start py-[0rem] pr-[2.25rem] pl-[2.125rem]">
                  <img
                    className="h-[2.75rem] w-[3.125rem] relative z-[9]"
                    alt=""
                    src="/vector-41.svg"
                  />
                </div>
                <div className="self-stretch relative inline-block min-w-[7.438rem] z-[9]">
                  Renal Medicine
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-center [row-gap:20px] mq1050:flex-wrap">
            <div className="flex-[0.6533] box-border flex flex-col items-start justify-start pt-[3.062rem] px-[3.5rem] pb-[3.125rem] gap-[0.5rem] min-w-[11.625rem] max-w-[15.5rem] z-[3] border-[0.2px] border-solid border-gray-300 mq450:flex-1">
              <div className="w-[15.5rem] h-[10.75rem] relative box-border hidden border-[0.2px] border-solid border-gray-300" />
              <div className="flex flex-row items-start justify-start py-[0rem] pr-[2.75rem] pl-[2.625rem]">
                <img
                  className="h-[2.75rem] w-[3.125rem] relative z-[4]"
                  alt=""
                  src="/vector-41.svg"
                />
              </div>
              <div className="self-stretch relative z-[4]">
                Gastroenterology
              </div>
            </div>
            <div className="flex-1 box-border flex flex-col items-center justify-start pt-[3.062rem] px-[1.25rem] pb-[3.125rem] gap-[0.5rem] min-w-[11.625rem] max-w-[15.5rem] z-[5] border-[0.2px] border-solid border-gray-300">
              <div className="w-[15.5rem] h-[10.75rem] relative box-border hidden border-[0.2px] border-solid border-gray-300" />
              <div className="flex flex-row items-start justify-start py-[0rem] px-[0.312rem]">
                <img
                  className="h-[2.75rem] w-[3.125rem] relative z-[6]"
                  alt=""
                  src="/vector-41.svg"
                />
              </div>
              <div className="w-[3.75rem] relative inline-block min-w-[3.75rem] z-[6]">
                Urology
              </div>
            </div>
            <div className="flex-[0.48] box-border flex flex-col items-start justify-start pt-[3.062rem] px-[4.625rem] pb-[3.125rem] gap-[0.5rem] min-w-[11.625rem] max-w-[15.5rem] z-[7] border-[0.2px] border-solid border-gray-300 mq450:flex-1">
              <div className="w-[15.5rem] h-[10.75rem] relative box-border hidden border-[0.2px] border-solid border-gray-300" />
              <div className="flex flex-row items-start justify-start py-[0rem] px-[1.562rem]">
                <img
                  className="h-[2.75rem] w-[3.125rem] relative z-[8]"
                  alt=""
                  src="/vector-41.svg"
                />
              </div>
              <div className="self-stretch relative inline-block min-w-[6.25rem] z-[8]">
                Dermatology
              </div>
            </div>
            <div className="flex-[0.48] box-border flex flex-col items-start justify-start pt-[3.062rem] px-[4.625rem] pb-[3.125rem] gap-[0.5rem] min-w-[11.625rem] max-w-[15.5rem] z-[9] border-[0.2px] border-solid border-gray-300 mq450:flex-1">
              <div className="w-[15.5rem] h-[10.75rem] relative box-border hidden border-[0.2px] border-solid border-gray-300" />
              <div className="flex flex-row items-start justify-start py-[0rem] px-[1.562rem]">
                <img
                  className="h-[2.75rem] w-[3.125rem] relative z-[10]"
                  alt=""
                  src="/vector-41.svg"
                />
              </div>
              <div className="self-stretch relative inline-block min-w-[6.25rem] z-[10]">
                Gynaecology
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MapperFunction;
